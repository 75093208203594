import React from 'react'
import '../../components/styles/contact-form.scss'
import SegmentedControl from '../../components/common/SegmentedControl'
import Layout from '../../components/common/Layout'
import ContactForm from '../../components/common/ContactForm'

export default function ResourcesAE() {

    return (
        <Layout active='ae-reporting'
        title='PharmaEssentia Medical Affairs | Report an Adverse Event'
        description='Report an adverse event that you observed while using a PharmaEssentia product to the PharmaEssentia medical team.'
        socialTitle='PharmaEssentia Medical Affairs | Report an Adverse Event'
        socialDescription='Report an adverse event that you observed while using a PharmaEssentia product to the PharmaEssentia medical team.'
        pathname='/resources/ae-reporting'
        >
            <section className='col margin-padding-zero align-center'>
                <div className='inner ae-bottom-margin'>
                    <h1>Resources</h1>
                    <SegmentedControl additionalClasses='space-between' buttons='resources' active='ae-reporting'/>
                    <h3>Report an Adverse Event</h3>
                    <p>To report an adverse event or product complaint, please call <a className="text-link" href="tel:+18009992449">1-800-999-2449</a> or email <a className='text-link' href="mailto:safety-us@pharmaessentia.com?subject=Report%20adverse%20event%20or%20Complaint">safety-us@pharmaessentia.com</a>.</p>
                    <p className='margin-bottom-fs-txt-leading'>If any adverse events occurred during a clinical trial, please contact the primary site investigator.</p>
                </div>
                {
                    // <ContactForm
                    // formId='ae-reporting'
                    // formClassName='bg-adverse'
                    // msgPlaceholder='Adverse event information*'
                    // includeInstitution='false'
                    // requireZip='false'/>
                }
                
            </section>
        </Layout>
    )
}