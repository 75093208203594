import React from 'react'

export default function ContactForm({
    formId='',
    formClassName='',
    msgPlaceholder='Message',
    includeInstitution='true',
    requireZip='true'
    }){ 

    const handleSubmit = (e) => {
        e.preventDefault()
        const   form = e.target,
                formData = new FormData(form),
                vName = formData.get('name'), 
                vSalutation = formData.get('salutation'), 
                vCity = formData.get('city'),
                vState = formData.get('state'),  
                vZip = formData.get('zip'),  
                vEmail = formData.get('email'),
                vMessage = formData.get('message'),
                hasName = vName !== '' && vName !== null,
                hasEmail = vEmail !== '' && vEmail !== null,
                hasMessage = vMessage !== '' && vMessage !== null,
                hasZip = vZip !== '' && vZip !== null,
                zipCheck =  hasZip && isRequireZip ? true
                            : !hasZip && isRequireZip ? false
                            : true,
                statePasses = (state) => {
                    const   states = [
                            'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 
                            'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 
                            'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 
                            'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 
                            'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 
                            'VA', 'WA', 'WV', 'WI', 'WY']
                    
                    for(let i = 0, len = states.length; i < len; i++){
                        if(state === states[i]) return true
                    }

                    return false
                },
                hasState = vState !== '' && vState !== null,
                stateCheck =    hasState && statePasses(vState) ? true
                                : !hasState ? true
                                : false,
                emailCheck = hasEmail && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(vEmail),
                isValid = hasName && hasMessage && emailCheck && zipCheck && stateCheck,
                mailSuccess = (data) => {
                    formStatus.innerHTML = 'Success! Your message has been sent.'
                    form.reset()
                },
                mailError = (err) => {
                    console.log(err)
                },
                sendMail = async (serialized) => {
                    try {
                        const response = await fetch('/api/mail-form.php', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/x-www-form-_urlencoded'
                            },
                            body: serialized
                        });
                        const data = await response.json();
                        mailSuccess(data);
                    } catch (err) {
                        mailError(err);
                    }
                },
                alerts = document.querySelectorAll('.alert'),
                formStatus = document.getElementById('form-status')
        
        console.log(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(vEmail))

        for(let i = 0, len = alerts.length; i < len; i++){
            const alert = alerts[i]
            alert.innerHTML = ''
            alert.classList.remove('show')
        }
        
        if(isValid) {
            const   serialized = [],
                    mailData = {
                        action: formId,
                        email: vEmail,
                        name: vName,
                        message: vMessage
                    },
                    hasSalutation = vSalutation !== '' && vSalutation !== null,
                    hasCity = vCity !== '' && vCity !== null

            //  form is valid - update status
            formStatus.innerHTML = 'sending...'
            
            //  check if we have any optional fields set
            hasSalutation && (mailData.salutation = vSalutation)
            hasCity && (mailData.city = vCity)
            hasState && (mailData.state = vState)
            hasZip && (mailData.zip = vZip)
            
            for (let key in mailData){
                serialized.push(encodeURIComponent(key) + '=' + encodeURIComponent(mailData[key]));
            }

            sendMail(serialized.join('&'))
        }
        else{
            if(!hasName){
                const nameAlert = document.querySelector('[data-input="name"]')
                nameAlert.classList.add('show')
                nameAlert.innerHTML = 'Required'
            }

            if(!emailCheck){
                const emailAlert = document.querySelector('[data-input="email"]')
                emailAlert.classList.add('show')
                emailAlert.innerHTML = 'Valid Email Required'
            }

            if(!hasMessage){
                const messageAlert = document.querySelector('[data-input="message"]')
                messageAlert.classList.add('show')
                messageAlert.innerHTML = 'Required'
            }

            if(isRequireZip && !hasZip){
                const messageAlert = document.querySelector('[data-input="zip"]')
                messageAlert.classList.add('show')
                messageAlert.innerHTML = 'Required'
            }

            if(hasState && !stateCheck){
                const messageAlert = document.querySelector('[data-input="state"]')
                messageAlert.classList.add('show')
                messageAlert.innerHTML = 'Invalid State'
            }
        }
    },
    isIncludeInstitution = includeInstitution === 'true',
    isRequireZip = requireZip === 'true',
    zipChange = (e) => {
        const   target = e.target,
                value = target.value

        target.value = value.replace(/[^0-9 ]/g, '')
        target.value.length > 5 && (target.value = value.substring(0,5))
    },
    stateChange = (e) => {
        const   target = e.target,
                value = target.value.toUpperCase()
        
        target.value = value.replace(/[^A-Z]/g, '')
        target.value.length > 2 && (target.value = value.substring(0,2))
    }
    
    return (
        <div id='contact-form' className={formClassName}>
            <div className='inner'>
                <form onSubmit={handleSubmit} className='col'>
                    <div className='row'>
                        <div className='col percent-75 alert-required'>
                            <input type='text' name='name' placeholder='First and last name*'/>
                            <p className='alert' data-input='name'></p>
                        </div>
                        
                        <div className='select-wrapper percent-25'>
                            <select id='results-select' name='salutation' defaultValue={'DEFAULT'}>
                                <option value='DEFAULT' disabled>Professional degree</option>
                                <option value='DO'>DO</option>
                                <option value='MBBS'>MBBS</option>
                                <option value='MD'>MD</option>
                                <option value='PharmD'>PharmD</option>
                                <option value='RPh'>RPh</option>
                                <option value='PA'>PA</option>
                                <option value='NP'>NP</option>
                                <option value='RN'>RN</option>
                                <option value='Other'>Other</option>
                            </select>
                            <span className='triangle-wrapper'>
                                <span className='triangle'></span>
                            </span>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='percent-75 row'>
                            <input className='row' type='text' name='city' placeholder='City' />
                        </div>

                        <div className='col percent-25 alert-required'>
                            <input className='row' type='text' name='state' onChange={stateChange} placeholder='State' />
                            <p className='alert' data-input='state'></p>
                        </div>

                        <div className='col percent-25 alert-required'>
                            {
                                isRequireZip
                                ? <input className='row' type='text' onChange={zipChange} name='zip' placeholder='ZIP code*' />
                                : <input className='row' type='text'  onChange={zipChange} name='zip' placeholder='ZIP code' />
                            }
                            <p className='alert' data-input='zip'></p>
                        </div>
                    </div>

                    <div className='col alert-required'>
                        <input className='row' type='email' name='email' placeholder='Email address*'/>
                        <p className='alert' data-input='email'></p>
                    </div>

                    {
                        isIncludeInstitution && <input className='row' type='text' name='institution' placeholder='Institution'/>
                    }

                    <div className='col alert-required'>
                        <textarea className='row' name='message' placeholder={msgPlaceholder} />
                        <p className='alert' data-input='message'></p>
                    </div>

                    <div className='row-bar-between'>
                        
                        <div className='row no-gap'>
                            <p className='sml'>*</p>
                            <p className='sml'>Required fields.</p>
                        </div>
                        <div className='row-right gapped mobile-col-reversed'>
                            <p id="form-status" className='bold'></p>
                            <input className='percent-25' type='submit' value='send' />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}